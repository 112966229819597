/* @import "~bootstrap/dist/css/bootstrap.css"; */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
body {
  font-family: "Roboto", "neue helvetica", sans-serif;
  background-color: #FFFFFF;
}


.side-spacing {
  /* padding-top: 40px; */
  padding-bottom: 40px;
  padding-right: 6%;
  padding-left: 6%;
}

.sub-header-label {
  font-size: 28px;
  font-weight: 400;
  color:#004A96;
}
.submit-btn {
  width: 140px !important;
  color: white !important;
  background-color: #004A96 !important;
  border-top-left-radius: 0px !important;
  margin: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}


.calculate-btn {
  width: 140px !important;
  color: white !important;
  background-color: #ff9100 !important;
  border-top-left-radius: 0px !important;
  margin: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}

.custom-sidenav {
  padding: 0px 15px;
  height: -moz-available;
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background: white;
  position: absolute;
  /* border-radius: 50px !important; */
}

.custom-padding {
  padding: 20px;
  min-height: 500px;
}



.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: white;
}

.p-slider .p-slider-handle:focus {
  box-shadow: none !important;
}

.min-height {
  min-height: calc(50vh - 300px);
}

.add-btn {
  color: white;
  background-color: #004A96;
  font-size: 12px;
  padding: 4px 30px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.excelButton {
  color: white;
  background-color: #ff9100;
  font-size: 12px;
  padding: 4px 20px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.p-inputtext {
  color: black
}

.pi-file-excel {
  font-size: 12px;
  margin-right: 10px;
}


.cancel-btn {
  width: 140px !important;
  color: white !important;
  background-color: #5a5a5a !important;
  margin: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}


.p-slider .p-slider-handle {
  height: .9rem;
  width: .9rem;
  background: #219fd2 !important;
  border: 2px solid #219fd2 !important;
}

.p-slider .p-slider-range {
  background: linear-gradient(45deg, #219fd2, #ff8e00) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.no-Padding {
  padding: 0px;
}

.body-gutter-spacing {
  padding: 0px 20px;
}

html {
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.p-radiobutton {
  margin-bottom: 4px !important;
}

.col-form-label {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.form-field .label {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p-datepicker.p-component{
  left: -150px !important;
}
.p-component-overlay {
  background-color: rgb(0 0 0 / 80%) !important;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-corner {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.img-logo {
  width: 153px !important;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.p-dropdown-item {
  font-weight: 400;
  font-size: 12px;
}

.p-multiselect-item {
  font-weight: 400;
  font-size: 12px;
}

:not(.p-highlight)>.p-tabview-nav-link {
  border: 1px solid white !important;
  border-color: white !important;
  background: white !important;
  border-bottom: 2px solid white !important;
}

.p-highlight>.p-tabview-nav-link {
  background: white !important;
  border-color: white !important;
  color: black !important;
  border-bottom: 4px solid #ff8f00 !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #ffffff00 !important;
}

.form-control:focus {
  border-color: black !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
}

.p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.2rem #eceff100 !important;
}

.p-tabview .p-tabview-panels {
  border: 0px;
  border-top: 1px solid #ff8f00 !important;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  box-shadow: 0 0 0 0.2rem #ffffff00;
}

.p-fieldset {
  margin-bottom: 8px;
}

.p-fieldset .p-fieldset-legend {
  background: #dbdbdb !important;
  border-color: #dbdbdb !important;
}

.p-multiselect-filter {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #eaeaea !important;
  color: black;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #8dcdff00 !important;
}





* {
  /* border-radius: 15px !important; */
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #bfdbe6;
  border-color: #bfdbe6;
  color: black;
}

.p-accordion .p-accordion-content {
  border-color: #bfdbe6;
  border: none
}


.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid #ffdcae;
  color: #333333;
  background: #ffdcae;
  padding: .8rem !important;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background-color: #bfdbe6;
  border-color: #bfdbe6;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background-color: #bfdbe6;
  border-color: #bfdbe6
}

.form-label label>sup {
  font-size: 10px;
  font-weight: 900;
  padding-left: 4px;
}

.form-label label:not(+ sup) {
  padding-right: 4px;
}

.form-field {
  padding: 0px !important;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
}

.p-paginator-bottom button {
  height: 30px !important;
  min-width: 30px !important;
}

.btn-primary {
  color: #fff;
  background-color: #004A96;
  border-color: #004A96;
}

.p-button-submit {
  color: #ffffff;
  background: #004A96 !important;
  border: 1px solid #004A96 !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  height:42px !important;
}

.p-button-admin {
  color: #ffffff;
  background: #004A96 !important;
  border: 1px solid #004A96 !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  height:46px !important;
}
.p-button-secondary {
  color: #ffffff;
  background: #5a5a5a !important;
  border: 1px solid #5a5a5a !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  height:42px !important;
}

.p-button-cancel {
  color: black !important;
  background: #ffffff !important;
  border: 1px solid red !important;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px !important;
  height:42px !important;
}
button.p-ripple.p-element.p-button-submit.btnstyleclass.p-button.p-component{
  padding-bottom: 0px;
  padding-top:0px;
}

button.p-ripple.p-element.p-button-cancel.btnstyleclass.p-button.p-component{
  padding-bottom: 0px;
  padding-top:0px;
}
button.p-ripple.p-element.p-button-secondary.btnstyleclass.p-button.p-component{
  padding-bottom: 0px;
  padding-top:0px;
}
.btncommon{
  color: #fff;
  background-color: #004A96;
  border-color: #004A96;
  height:42px;
}

.resetFormStyles {
  margin-top: 30px;
  margin-left: 0px;
  margin-bottom: 88px;
}

.resetFormStyles>.form-label>span {
  font-size: 14px;
}

.resetFormStyles h4 {
  font-size: 24px;
}

.resetFormStyles .row {
  padding-bottom: 20px;
}

@media (min-width: 1000px) {
  .container {
    min-width: 920px;
  }
  .header-label {
    font-size: 46px;
    font-weight: 400;
  }
}

@media (min-width: 1100px) {
  .container {
    min-width: 1020px;
  }
}

@media (min-width: 1200px) {
  .container {
    min-width: 1120px;
  }
}

@media (min-width: 1300px) {
  .container {
    min-width: 1220px;
  }
  .header-label {
    font-size: 46px;
    font-weight: 400;
  }
}

@media (min-width: 1400px) {
  .container {
    min-width: 1320px;
  }
  .header-label {
    font-size: 46px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1420px;
  }
  .header-label {
    font-size: 46px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
}

@media (min-width: 1600px) {
  .container {
    min-width: 1520px;
  }
}

@media (min-width: 1700px) {
  .container {
    min-width: 1620px;
  }
  .header-label {
    font-size: 52px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
}

@media (min-width: 1800px) {
  .container {
    min-width: 1720px;
  }
  .header-label {
    font-size: 52px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
}

@media (min-width: 1900px) {
  .container {
    min-width: 1820px;
  }
  .header-label {
    font-size: 52px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
}

.logoutIcon {
  display: block;
}

/* ========================================prime ng============================================ */
.p-button.cancel:enabled:hover {
  background: gray;
  color: #ffffff;
  border-color: #0c0c0c00;
}

.p-button.accept:enabled:hover {
  background: #004890;
  color: #ffffff;
  border-color: #0c0c0c00;
}
.p-button:enabled:hover {
  background: #004890;
  color: #ffffff;
  border-color: #004890;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
}


.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border: 1px solid #a6a6a6;
  background: gray;
}

/* .p-checkbox-focused{
  box-shadow: 0 0 0 0.2rem #0c0c0c00 !important;
} */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border: 1px solid #a6a6a6;
  background: gray;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-tabview-title {
  font-weight: 300 !important;
}

.p-highlight .p-tabview-title {
  font-weight: 700 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border: 1px solid #a6a6a6;
  background: rgba(128, 128, 128, 0);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border: 1px solid #a6a6a6;
  background: rgba(128, 128, 128, 0);
  color: #ffffff;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: visible;
  background: gray;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
}

.p-accordion-header-link {
  color: #676767 !important;
}

.p-dropdown-label {
  font-size: 14px;
  padding: 0px;
  margin: 3px 4px 0px;
}

.p-multiselect-label {
  font-size: 14px;
  padding: 0px;
}

.p-checkbox-box {
  width: 16px !important;
  height: 16px !important;
  margin-top: 2px !important;
}

.form-control {
  padding: 1px 8px !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 5px !important;
}

.p-paginator .p-dropdown {
  height: 2rem;
}

.row {
  align-items: center;
}

.container {
  min-width: 100% !important;
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem #8dceff00 !important;
}

.navbar-light .navbar-toggler {
  border-color: rgb(0 0 0 / 0%) !important;
}

.navbar-toggler {
  padding: 0px;
}

@media only screen and (min-width: 1px) and (max-width: 479px) {
  .header-navbar-ul>li:hover>a {
    color: white !important;
  }

  
  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }

  .header-navbar>ul li+li {
    margin-left: 0px !important;
  }

  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  .header-logo-text {
    padding-right: 2px !important;
  }

  .header-navbar {
    background-color: #d5d5d5 !important;
    padding: 0px;
    padding-right: 0px !important;
    border-top: 1px solid white;
    z-index: 1 !important;
  }

  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }

  .header-navbar>ul {
    align-items: flex-end !important;
  }

  .header-navbar-ul {
    padding: 0px !important;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }

  .nav-item {
    width: 100% !important;
    padding: 4px 50px !important;
    text-align: left !important;
  }

  .nav-item:hover {
    background-color: gray !important;
  }

  #mobile-home-image {
    display: block !important;
  }

  #mobile-not-home-image {
    display: none !important;
  }

  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }

  .active {
    background-color: transparent !important;
    color: black !important;
  }

  .active-img {
    background-color: transparent !important;
  }

  .form {
    padding-right: 0px !important;
  }

  .navbar-collapse {
    position: absolute;
    left: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }

  .header-navbar-ul>ul {
    align-items: flex-start;
  }

  li+li {
    margin-left: 0px !important;
  }

  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }

  .home-button-grp span {
    display: none !important;
  }

  .home-button-grp br {
    display: none !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }

  .form-label {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .header-logo-text {
    padding-right: 106px !important;
  }

  
  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  .header-navbar-ul>li:hover>a {
    color: white !important;
  }

  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }

  #mobile-home-image {
    display: block !important;
  }

  #mobile-not-home-image {
    display: none !important;
  }

  .header-navbar {
    background-color: #d5d5d5 !important;
    padding: 0px;
    padding-right: 0px !important;
    border-top: 1px solid white;
    z-index: 1 !important;
  }

  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }

  .header-navbar>ul {
    align-items: flex-end !important;
  }

  .header-navbar-ul {
    padding: 0px !important;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }

  .nav-item {
    width: 100% !important;
    padding: 4px 50px !important;
    text-align: left !important;
  }

  .nav-item:hover {
    background-color: gray !important;
  }

  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }

  .active {
    background-color: transparent !important;
    color: black !important;
  }

  .active-img {
    background-color: transparent !important;
  }

  .form {
    padding-right: 0px !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.557rem 1rem !important;
  }

  .navbar-collapse {
    position: absolute;
    left: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }

  .header-navbar-ul>ul {
    align-items: flex-start;
  }

  li+li {
    margin-left: 0px !important;
  }

  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }

  .home-button-grp span {
    display: none !important;
  }

  .home-button-grp br {
    display: none !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }

  .form-label {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
 
  .header-label {
    font-size: 46px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }
  .header-navbar-ul>li:hover>a {
    color: white !important;
  }

  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }

  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  #mobile-home-image {
    display: block !important;
  }

  #mobile-not-home-image {
    display: none !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }

  .header-logo-text {
    padding-right: 394px !important;
  }

  .header-navbar {
    background-color: #d5d5d5 !important;
    padding: 0px;
    padding-right: 0px !important;
    border-top: 1px solid white;
    z-index: 1 !important;
  }

  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }

  .header-navbar>ul {
    align-items: flex-end !important;
  }

  .header-navbar-ul {
    padding: 0px !important;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }

  .nav-item {
    width: 100% !important;
    padding: 4px 50px !important;
    text-align: left !important;
  }

  .nav-item:hover {
    background-color: gray !important;
  }

  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }

  .active {
    background-color: transparent !important;
    color: black !important;
  }

  .active-img {
    background-color: transparent !important;
  }

  .form {
    padding-right: 0px !important;
  }

  .navbar-collapse {
    position: absolute;
    left: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }

  .header-navbar-ul>ul {
    align-items: flex-start;
  }

  li+li {
    margin-left: 0px !important;
  }

  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }

  .home-button-grp span {
    display: none !important;
  }

  .home-button-grp br {
    display: none !important;
  }

  .form-label {
    font-size: 8px !important;
  }
}

.paddingClassButtons {
  padding: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #mobile-home-image {
    display: none !important;
  }
  .header-label {
    font-size: 32px;
    font-weight: 400;
    display: block; white-space: nowrap;
  }

  #mobile-not-home-image {
    display: block !important;
  }

  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 3000px) {
  #mobile-home-image {
    display: none !important;
  }

  #mobile-not-home-image {
    display: block !important;
  }
}

@media (max-width:991px) {
  .logoutIcon {
    display: none;
  }
}


@media (max-width:767px) {

  .submit-btn,
  .cancel-btn,
  .add-btn,
  .excelButton {
    width: 50% !important;
  }

  .mobileButtonClass {
    position: fixed;
    bottom: 39px;
    left: 0px;
    padding: 0px;
    z-index: 2;
  }
  .paddingClassButtons {
    padding: 0px;
  }
 

  #showGridClass .p-multiselect {
    width: 100% !important;
    text-align: left !important;
  }

  .p-paginator-bottom button {
    height: 25px !important;
    min-width: 25px !important;
  }

  .custom-sidenav {
    /* min-height: calc(50vh - 40px); */
    padding: 0px 30px;
    width: 100%;
    padding-bottom: 100px;
  }



}

@media only screen and (min-width: 768px) and (max-width: 840px) {
  .custom-sidenav {
    width: 60%;
  }
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
      stroke: #16697A;
  }
  40% {
      stroke: #489FB5;
  }
  66% {
      stroke: #82C0CC;
  }
  80%,
  90% {
      stroke: #FFA62B;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}